.main-page {
    width: 100%;
    min-height: calc(100vh - 100px);
    float: left;
}

.main-page .intro {
    width: 90%;
    float: left;
    margin: 0 5%;
}

.main-page .textplace {
    width: 100%;
    display: flex;
    padding: 50px 0;
    letter-spacing: 0.55px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    margin-top: 120px;
}

.main-page .textplace .section {
    width: 260px;
    margin: 0 auto;
    margin-top: 50px;
    min-height: 250px;
}

@media only screen and (max-width: 600px) {
    .main-page .textplace{
        margin-top: 0;
        width: 100%;
        display: block;
        padding: 20px 0;
    }
  
    .main-page .textplace .section{
        width: 280px;
    }
}

.main-page .textplace .section .icon-1 {
    width: 120px;
    height: 120px;
    margin: auto;
    background: url("images/cheapest-flights.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.main-page .textplace .section .icon-1 {
    background: url("images/cheapest-flights.png");
}

.main-page .textplace .section .icon-2 {
    background: url("images/multi-city.png");
}

.main-page .textplace .section .icon-3 {
    background: url("images/cheapest-trip.png");
}

.main-page .textplace .section .icon-1,
.main-page .textplace .section .icon-2,
.main-page .textplace .section .icon-3 {
    width: 120px;
    height: 120px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.main-page .textplace .section h2 {
    color: #212F3C;
}

.main-page .textplace .section p { 
    color: #616161;
    font-size: 20px;
    line-height: 30px;
    text-shadow: 0 1px 1px #ffffff;
    padding-top: 15px;
}