.search-page {
    width: 100%;
    float: left;
}

@media only screen and (min-width: 900px) {
    .search-page {
        height: 100vh;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 900px) {
    .search-page {
        min-height: 100vh;
    }
}

.search-page .loading {
    width: 150px;
    height: 150px;
    margin: auto;
    margin-top: calc(100vh / 2 - 100px);
}

.search-page .loading .progress {
    text-align: center;
    color: #212F3C;
    margin-top: -100px;
    font-size: 50px;
    margin-bottom: 50px;
}

.search-page .loading .directions {
    width: 200px;
    margin-left: -25px;
    text-align: center;
    color: #212F3C;
    font-size: 20px;
    letter-spacing: 0.55px;
    font-family: 'Inter', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

.search-page .loading .loader {
    border: 15px solid #212F3C;
    border-radius: 50%;
    border-top: 15px solid #EEF1F7;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.search-page .error {
    width: 100%;
    height: 100vh;
    line-height: 100vh;
    text-align: center;
    font-size: 25px;
    letter-spacing: 0.55px;
    font-family: 'Inter', sans-serif;
    color: #212F3C;
}