/* ----- Search Tool ----- */
.search-tool {
    width: 26%;
    height: 100vh;
    float: left;
    background-color: #212F3C;
}

@media only screen and (max-width: 900px) {
    .search-tool {
        width: 100%;
    }
}

.search-tool h1 {
    float: left;
    color: #ffffff;
    width: calc(100% - 80px);
    margin: 135px 40px 15px 40px;
    font-size: 40px;
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
}

.search-tool .flight-option {
    float: left;
    width: calc(100% - 50px);
    padding: 5px 0;
    min-height: 15px;
    margin: 15px 25px 10px 25px;
    color: #ffffff;
    letter-spacing: 0.55px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    line-height: 28px;
}

.search-tool .flight-option .option,
.search-tool .flight-option .active-option {
    float: left;
    border-bottom: 3px solid #566573;
    cursor: pointer;
    margin-right: 12px;
    color: #ffffff;
}

.search-tool .flight-option .active-option {
    border-bottom: 3px solid #ffffff;
}

.search-tool .flight-option .option:hover {
    border-bottom: 3px solid #ffffff;
}

.search-tool .origin, 
.search-tool .dates, 
.search-tool .destinations {
    border: 1px solid #ffffff;
    float: left;
    height: 25px;
    padding: 10px 0;
    width: calc(100% - 50px);
    margin: 10px 25px;
    border-radius: 10px;
    cursor: pointer;
}

.search-tool .destinations {
    background-color: #ffffff;
}

.search-tool .destinations .icon {
    width: 30px;
    margin-left: 10px;
    height: 25px;
    float: left;
    background: url("images/search-icon.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.search-tool .origin .name,
.search-tool .dates .name {
    margin-top: -25px;
    margin-left: 15px;
    color: #ffffff;
    background-color: #212F3C;
    width: 55px;
    text-align: center;
    font-variant: small-caps;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.55px;
    font-family: 'Inter', sans-serif;
}

.search-tool .dates .name {
    width: 60px;
}

.search-tool .origin p,
.search-tool .dates p,
.search-tool .destinations p {
    width: calc(100% - 20px);
    margin: 5px 10px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.55px;
    font-family: 'Inter', sans-serif;
}

.search-tool .destinations p {
    width: calc(100% - 60px);
    color: #212F3C;
}

.search-tool .button {
    width: calc(100% - 50px);
    margin: 20px 25px 0 25px;
    float: left;
    height: 45px;
    background-color: #69BD90;
    color: #ffffff;
    border-radius: 10px;
    border: #69BD90;
    cursor: pointer;
    font-variant: small-caps;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    line-height: 45px;
    letter-spacing: 0.55px;
}

.search-tool .button:hover {
    background-color: #51896B;
}

.search-tool .input-background {
    position: fixed;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    z-index: 2;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.1); /* Black w/opacity/see-through */
}

.search-tool .input-background .input {
    width: 26%;
    float: left;
    height: 100vh;
    background-color: #f1f1f1;
}

@media only screen and (max-width: 900px) {
    .search-tool .input-background .input {
        width: 100%;
        height: 100vh;
        float: left;
        background-color: #f1f1f1;
    }
}

.search-tool .input-background .input .exit {
    margin: 10px 3% 0 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 2.2em;
    float: right;
    text-align: center;
    transform: rotate(-45deg);
    color: #212F3C;
    cursor: pointer;
}

.search-tool .input-background .input .input-name {
    margin: 10px 0 0 5%;
    width: 70%;
    height: 40px;
    float: left;
    color: #212F3C;
    font-weight: bold;
    font-size: 2em;
    letter-spacing: 0.55px;
    font-family: "Times New Roman", Times, serif;
}

.search-tool .input-background .input .origin-input {
    margin: 10px 5% 0 5%;
    width: 86%;
    height: 38px;
    border-radius: 10px;
    border: 1px solid #E5E7E9;
    font-size: 1.1em;
    outline: none;
    padding-left: 3%;
    letter-spacing: 0.55px;
    box-shadow: 5px 10px 15px -3px rgba(0,0,0,0.2);
}

.search-tool .input-background .input .destination-input {
    margin: 10px 10px 0 5%;
    width: calc(90% - 78px);
    height: 38px;
    border-radius: 10px;
    border: 1px solid #E5E7E9;
    font-size: 1.1em;
    outline: none;
    padding-left: 3%;
    float: left;
    box-shadow: 5px 10px 15px -3px rgba(0,0,0,0.2);
}

.search-tool .input-background .input .next-button {
    margin-top: 10px;
    width: 50px;
    height: 42px;
    border-radius: 10px;
    float: left;
    background: url("images/next-icon.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: #212F3C;
    cursor: pointer;
}

.search-tool .input-background .input .collect-tag {
    margin: 5px 5% 0 5%;
    width: 90%;
    min-height: 5px;
    float: left;
    display: flex;
    flex-wrap: wrap;
}

.search-tool .input-background .collect-tag .tag {
    height: 30px;
    max-width: 100%;
    background-color: #51896B;
    border-radius: 15px;
    padding-top: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 2% 5px 0;
}

.search-tool .input-background .collect-tag span {
    color: #ffffff;
    max-width: 88%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.55px;
    padding-left: 3px;
}

.search-tool .input-background .collect-tag .close {
    float: right;
    margin: 0 4px 0 5px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    cursor: pointer;
    text-align: center;
    transform: rotate(-45deg);
    font-size: 1.4em;
    background-color: #426953;
    color: #ffffff;
}

.search-tool .input-background .collect-tag .close:hover {
    background-color: #385B48;
}

.search-tool .input-background .input .autocomplete {
    margin-top: 10px;
    width: 100%;
    min-height: 5px;
    max-height: 340px;
    overflow-x: hidden;
    overflow-y: scroll;
    float: left;
}

.search-tool .input-background .input .autocomplete .autocomplete-tag,
.search-tool .input-background .input .autocomplete .active-autocomplete-tag {
    width: 100%;
    min-height: 30px;
    float: left;
    color: #212F3C;
    font-size: 1.3em;
    padding: 9px 0 9px 5%;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.55px;
    border-bottom: 1px solid #C8C8C8;
    cursor: pointer;
}

.search-tool .input-background .input .autocomplete .active-autocomplete-tag {
    background-color: #212F3C;
    color: #ffffff;
}

.search-tool .input-background .input .autocomplete .autocomplete-tag:hover {
    background-color: #C8C8C8;
}

.search-tool .input-background .input .week-names {
    width: 90%;
    min-height: 10px;
    margin: 10px 5% 0 5%;
    float: left;
    border-bottom: 1px solid #212F3C;
}

.search-tool .input-background .input .week-names .weekdays {
    margin-top: 0;
    width: 14.2%;
    height: 20px;
    float: left;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
    color: #212F3C;
}

.search-tool .input-background .input .week-names .weekends {
    margin-top: 0;
    width: 14.2%;
    height: 20px;
    float: left;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
    color: #A93226;
}

.search-tool .input-background .input .month-box {
    width: 90%;
    min-height: 10px;
    max-height: 350px;
    margin: 10px 5% 0 5%;
    float: left;
    overflow-x: hidden;
    overflow-y: scroll;
}

.search-tool .input-background .input .month-box .month-name {
    width: 90%;
    min-height: 10px;
    margin: 10px 5% 0 5%;
    float: left;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    color: #212F3C;
    font-family: "Times New Roman", Times, serif;
}

.search-tool .input-background .input .month-box .days {
    width: 100%;
    min-height: 10px;
    float: left;
}

.search-tool .input-background .input .month-box .days .passed,
.search-tool .input-background .input .month-box .days .today,
.search-tool .input-background .input .month-box .days .day,
.search-tool .input-background .input .month-box .days .choosedFrom,
.search-tool .input-background .input .month-box .days .selected,
.search-tool .input-background .input .month-box .days .choosedTill {
    margin-top: 2px;
    width: 14.2%;
    height: 20px;
    float: left;
    font-size: 1.2em;
    text-align: center;
    padding: 10px 0;
}

.search-tool .input-background .input .month-box .days .empty {
    margin-top: 2px;
    width: 14.2%;
    height: 40px;
    float: left;
}

.search-tool .input-background .input .month-box .days .passed {
    color: #808080;
}

.search-tool .input-background .input .month-box .days .today {
    color: #A93226;
}

.search-tool .input-background .input .month-box .days .day {
    color: #212F3C;
    cursor: pointer;
}

.search-tool .input-background .input .month-box .days .day:hover {
    background-color: #C8C8C8;
}

.search-tool .input-background .input .month-box .days .selected {
    color: #ffffff;
    background-color: #212F3C;
    cursor: pointer;
}

.search-tool .input-background .input .list {
    float: left;
    width: 100%;
    height: calc(100% - 150px);
    color: #212F3C;
    outline: none;
    cursor: pointer;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 1px solid #C5C9CC;
}

.search-tool .input-background .input .list .currency {
    text-align: center;
    padding: 7px 0;
    width: 100%;
    min-height: 15px;
}
  
.search-tool .input-background .input .list .currency:hover {
    background-color: #e9e9e9;
}