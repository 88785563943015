html, body {
    height: 100%;
    width: 100%;
    min-width: 300px;
    margin: 0;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body {
    /* font-family: Arial, Verdana, Helvetica, sans-serif; */
    position: relative;
}

main {
    width: 100%;
    flex: 1;
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.wrapper {
  min-height: 100vh;
  width: 74%;
  display: flex;
  float: left;
  flex-direction: column;
  max-width: 3000px;
  margin: 0 auto 0 auto;
  background-color: #EEF1F7;
}

@media only screen and (max-width: 600px) {
    .wrapper {
        width: 100%;
    }
}

.footer {
    width: 100%;
    min-height: 79px;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    text-align: center;
    letter-spacing: 0.55px;
    font-family: 'Inter', sans-serif;
}

@media only screen and (max-width: 600px) {
    .footer {
        font-size: 14px;
    }
}

.footer a {
    text-decoration: none;
    color: #616161;
}

.footer a:hover {
    text-decoration: underline;
}

.footer p {
    margin: 5px 20px;
    color: #616161;
}